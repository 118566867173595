<template>
  <section class="proposal-documents">
    <div class="m-1 mr-4 px-0">
      <breadcrumb title="Documentos" actualPage="Lista de documentos" />
    </div>
    <div class="vx-row">
      <div
        class="vx-col pt-5 text-right w-full mt-2"
        v-if="
          profileHelpers.checkPermission([
            profileHelpers.menuFuncionalitiesEnum.GERENCIAR_DOCUMENTOS
          ])
        "
      >
        <vs-button @click.native="openDialogUpload()" color="primary">
          <span class="font-semibold pr-2 pl-2">
            Upload de documento
          </span>
        </vs-button>
      </div>
    </div>
    <vs-table
      class="mt-10 table-available"
      :data="RequestDocuments"
      noDataText="Nenhum arquivo foi cadastrado"
    >
      <template slot="thead">
        <vs-th>
          Nome
        </vs-th>
        <vs-th>
          Data de inserção
        </vs-th>
        <vs-th>
          Ações
        </vs-th>
      </template>
      <template>
        <vs-tr
          :data="document"
          :key="indextr"
          v-for="(document, indextr) in RequestDocuments"
        >
          <vs-td>{{ document.Name }}</vs-td>
          <vs-td>{{ document.CreateDate | moment("DD/MM/YYYY HH:mm") }}</vs-td>
          <vs-td>
            <vs-button
              class="p-0"
              color="dark"
              type="flat"
              title="Download do documento"
              @click.native="downloadDocument(document.UniqueId)"
            >
              <i class="onpoint-download-simple "></i>
            </vs-button>
            <vs-button
              v-if="
                profileHelpers.checkPermission([
                  profileHelpers.menuFuncionalitiesEnum.GERENCIAR_DOCUMENTOS
                ])
              "
              class="p-0"
              color="dark"
              type="flat"
              title="Editar nome do documento"
              @click.native="openDialogEditDocument(document.UniqueId)"
            >
              <i class="onpoint-pencil-simple-line"></i>
            </vs-button>
            <vs-button
              v-if="
                profileHelpers.checkPermission([
                  profileHelpers.menuFuncionalitiesEnum.GERENCIAR_DOCUMENTOS
                ])
              "
              class="p-0"
              color="dark"
              type="flat"
              title="Excluir documento"
              @click.native="
                openDialogDeleteDocument(document.UniqueId, document.Name)
              "
            >
              <i class="onpoint-trash"></i>
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="download-all mt-1 mr-5" @click="downloadAllDocuments() ">
      <span>Baixar todos</span><i class="onpoint-download"></i>
    </div>
    <base64Upload
      v-if="openDialogAddDocumente"
      :modalUpload="openDialogAddDocumente"
      :documentoName="''"
      :insertName="true"
      @callBackUpload="callBackUpload"
      @closeModal="closeModal"
    />
    <!-- <vs-popup
      class="popup-upload-document z-index-modal"
      title="Upload de documento"
      :active.sync="openDialogAddDocumente"
    >
      <div class="vx-row flex justify-center">
        <div>
          <az-upload
            :singleUpload="true"
            :controller="uploadDocumentController"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="flex font-semibold">Nomear documento:</p>
          <vs-input class="w-full" v-model="documentName" size="large" />
        </div>
      </div>
      <div class="vx-row flex justify-end">
        <div>
          <vs-button
            @click.native="uploadDocument(documentName)"
            color="primary"
          >
            <span class="font-semibold pr-2 pl-2">
              Enviar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup> -->
    <vs-popup
      class="popup-edit-documents z-index-modal"
      title="Editar nome"
      :active.sync="openEditDocument"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="flex font-semibold">Informe o novo nome</p>
          <vs-input class="w-full" v-model="newDocumentName" size="large" />
        </div>
      </div>
      <div class="vx-row flex justify-end mt-5">
        <div>
          <vs-button @click.native="editDocument()" color="primary">
            <span class="font-semibold pr-2 pl-2">
              Confirmar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      class="popup-delete-documents z-index-modal"
      title="Deletar arquivo"
      :active.sync="openDeleteDocument"
    >
      <div class="vx-row flex justify-center">
        <div>
          <h4>Confirma a ação de deleter o documento?</h4>
        </div>
      </div>
      <div class="vx-row flex justify-center mt-5">
        <div class="document-name">{{ documentName }}</div>
      </div>
      <div class="vx-row flex justify-center mt-5">
        <div>
          <vs-button
            color="dark"
            class="mr-10"
            @click.native="openDeleteDocument = false"
          >
            <span class="font-semibold pr-2 pl-2">
              Voltar
            </span>
          </vs-button>
          <vs-button
            @click.native="deleteDocument(RequestDocumentUniqueId)"
            color="dark"
          >
            <span class="font-semibold pr-2 pl-2">
              Confirmar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Upload from "@/components/az-upload/AzUpload.vue";
import { documentProvider } from "@/providers/document-provider";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import base64Upload from "@/components/base64-Upload/index.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import instance from "@/axios";

export default {
  name: "document-list",
  components: {
    Upload,
    Breadcrumb,
    base64Upload
  },
  watch: {},
  data() {
    return {
      openDialogAddDocumente: false,
      openEditDocument: false,
      openDeleteDocument: false,
      documentName: "",
      newDocumentName: "",
      uploadDocumentController: {},
      RequestDocumentUniqueId: null,
      listChanged: false,
      RequestDocuments: [],
      loading: true,
      profileHelpers: PerfilHelpers
    };
  },
  mounted() {
    this.loadDocuments();
  },
  created() {
    this.$appInsights.trackPageView({
      name: "documentos",
      url: window.location.href
    });
  },
  computed: {
    ...mapGetters("document-module", ["documents"])
  },
  methods: {
    ...mapActions("document-module", ["getDocumentsList"]),
    closeModal() {
      this.openDialogAddDocumente = false;
    },
    callBackUpload(callback) {
        this.openDialogAddDocumente = false;
      return instance({
        method: "post",
        url: "api/InsuranceCompany/CreateRequestDocument",
        data: {
          RequestDocument: callback.documentoText
        }
      })
        .then(data => {
          let request = {
            RequestDocumentUniqueId: data.data.Response.RequestDocument.UniqueId,
            files: [
              {
                name: callback.callback.data.Response.Filename,
                uniqueIdentifier: callback.callback.data.Response.UniqueId,
                extension: callback.callback.data.Response.Extension,
                relativePath: callback.callback.data.Response.Filename
              }
            ]
          };
          documentProvider.uploadDocumento(request).then(response => {
            this.$onpoint.successModal("Documento enviado com sucesso.");
            this.loadDocuments();
          });
        })
        .catch(ex => {
          this.$onpoint.errorModal(ex);
        });
    },
    // uploadDocument(nameDocument) {
    //   const files = this.uploadDocumentController.files();
    //   if (!files || files.length == 0 || this.documentName === "") {
    //     this.$onpoint.errorModal("Todos os campos são obrigatórios.");
    //     return;
    //   }
    //   // this.$onpoint.loadingModal("Estamos enviando seu documento.", () => {
    //   this.$vs.loading();
    //   documentProvider
    //     .createDocument({
    //       RequestDocument: nameDocument,
    //       ProposalUniqueId: this.$route.params.propostaUniqueId
    //     })
    //     .then(data => {
    //       this.uploadDocumentController.upload().then(uploadedFiles => {
    //         const request = {
    //           ProposalUniqueId: this.$route.params.propostaUniqueId,
    //           RequestDocumentUniqueId: data.RequestDocument.UniqueId,
    //           Files: uploadedFiles
    //         };
    //         return documentProvider.uploadDocumento(request).then(response => {
    //           this.$vs.loading.close();
    //           this.$onpoint.successModal("Documento enviado com sucesso.");
    //           this.openDialogAddDocumente = false;
    //           this.loadDocuments();
    //         });
    //       });
    //     })
    //     .catch(ex => {
    //       this.$onpoint.errorModal(ex);
    //     });
    //   // });
    // },
    downloadDocument(RequestDocumentUniqueId) {
      this.$onpoint.loading(() => {
        return documentProvider
          .getRequestDocumentArchives(RequestDocumentUniqueId)
          .then(resp => {
            if (resp.length === 0)
              return this.$onpoint.errorModal("Nenhum anexo nesse documento!");
            resp.map(document => {
              documentProvider
                .getArchiveLinkToDownload(document.UniqueId)
                .then(data => {
                  window.open(data, "_blank");
                })
                .catch(ex => {
                  this.$onpoint.errorModal(ex);
                });
            });
          });
      });
    },
    loadDocuments() {
      this.$onpoint.loadingModal("Estamos buscando os documentos", () => {
        return this.getDocumentsList().then(
          documents => (this.RequestDocuments = this.documents)
        );
      });
    },
    editDocument() {
      this.$onpoint.loadingModal("Estamos alterando seu documento.", () => {
        return documentProvider
          .editNameDocument({
            RequestDocumentUniqueId: this.RequestDocumentUniqueId,
            ProposalUniqueId: this.$route.params.propostaUniqueId,
            Name: this.newDocumentName
          })
          .then(resp => {
            this.openEditDocument = false;
            this.$onpoint.successModal(
              "Nome do documento alterado com sucesso!"
            );
            this.loadDocuments();
          });
      });
    },
    downloadAllDocuments() {
      this.documents.map(document => {
        this.downloadDocument(document.UniqueId);
      });
    },
    deleteDocument() {
      this.$onpoint.loadingModal("Estamos deletando seu documento.", () => {
        return documentProvider
          .deleteDocument({
            ProposalUniqueId: this.$route.params.propostaUniqueId,
            RequestDocumentUniqueId: this.RequestDocumentUniqueId
          })
          .then(data => {
            this.openDeleteDocument = false;
            this.$onpoint.successModal("Operação realizada com sucesso!");
            this.loadDocuments();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    openDialogUpload() {
      this.documentName = "";
      this.openDialogAddDocumente = true;
    },
    openDialogEditDocument(RequestDocumentUniqueId) {
      this.newDocumentName = "";
      this.openEditDocument = true;
      this.RequestDocumentUniqueId = RequestDocumentUniqueId;
    },
    openDialogDeleteDocument(RequestDocumentUniqueId, documentName) {
      this.documentName = this.openDeleteDocument = true;
      this.RequestDocumentUniqueId = RequestDocumentUniqueId;
      this.documentName = documentName;
    }
  }
};
</script>
<style lang="scss">
.table-available table thead tr {
  background: rgba(var(--vs-primary), 1);
  color: #fff;
}
.con-vs-popup .vs-popup {
  width: 500px;
  padding: 30px;
}
.font-icons {
  font-size: 17px !important;
  margin: 10px;
}
.document-name {
  background: #f8f8f8;
  padding: 20px 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #0f6eb4;
}
.download-all {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  color: rgba(var(--vs-primary), 1);
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--vs-primary), 1);
  }
}
.restore {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0f6eb4;
}
.z-index-modal {
  z-index: 52999;
}

.onpoint-trash::before{
  font-size: 20px;
  position: relative;
  top: 1px;
}
.onpoint-pencil-line::before{
  font-size: 20px;
}
.onpoint-download-simple::before{
  font-size: 20px;
}
.onpoint-download::before{
  font-size: 20px;
  position: relative;
  top: 2px;
  right:10px;
}
</style>
